import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  exchange: null,
  sale: null,
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    saveExchangeFilters(state, action) {
      state.exchange = action.payload;
    },

    saveSaleFilters(state, action) {
      state.sale = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function saveFilters(type: 'sale' | 'exchange', filters: object) {
  return () => {
    dispatch(type === 'sale' ? slice.actions.saveSaleFilters(filters) : slice.actions.saveExchangeFilters(filters));
  };
}

// ----------------------------------------------------------------------
