import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { SeverErrorIllustration } from '../assets';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function NoAccess() {
  const { translate } = useLocales();

  return (
    <Page title={`${translate('GLOBAL.NO_ROOTS')}`} sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              {translate('GLOBAL.NO_ACCESS')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>{translate('GLOBAL.CALL_ADMINISTRATOR')}</Typography>

            <SeverErrorIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

            <Button to="/" size="large" variant="contained" component={RouterLink}>
              {translate('GLOBAL.GO_HOME')}
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
