import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import  AdminLogo  from '../assets/AdminLogo.png';
import  KTSLogo  from '../assets/KTSLogo.png';
import Image from './Image';

// ----------------------------------------------------------------------

interface LogoProps {
  disabledLink?: boolean;
  isCollapse?: boolean;
  sx?: object,
}

export default function Logo({ disabledLink = false, isCollapse, sx }: LogoProps) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: 50,  ...sx}}>
      <Box sx={{ width: 50, height: '100%',}}>
        <img
          style={{height: '100%'}}
          alt={'logo'}
          src={AdminLogo}/>
      </Box>
      {
        !isCollapse &&
        <Box sx={{ width: 50, height: '100%' }}>
        <img
          style={{ height: '100%' }}
          alt={'logo'}
          src={KTSLogo} />
      </Box>}
    </Box>

  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
