import numeral from 'numeral';
import { string } from 'yup';

// ----------------------------------------------------------------------

export function fCurrency(number: number) {
  return numeral(number).format(Number.isInteger(number) ? `₸0,0` : `₸0,0.00`);
}
export function fCurrencyString(currency: string) {
  switch (currency) {
    case 'KZT':
      return '₸'
    case 'UZS':
      return ' сум'
    case 'AED':
      return ' AED'
    default:
      return '₸'
  }
}

export function fPercent(number: number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number: number) {
  const formatter = new Intl.NumberFormat("ru");
  return formatter.format(number);
}

export function fShortenNumber(number: number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: number) {
  return numeral(number).format('0.0 b');
}
