import React, { useEffect, useState } from 'react';
import useHelper from '../hooks/useHelper';
import NoAccess from '../pages/NoAccess';

// ----------------------------------------------------------------------

interface AccessGuardProps {
  children: React.ReactNode,
  claims: any[],
}

export default function AccessGuard({ children, claims }: AccessGuardProps) {
  const { hasUserAccess } = useHelper();

  return hasUserAccess(claims) ? <>{children}</> : <NoAccess />;
}
