import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { ChangeEvent, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// utils
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router';
import { fToNow } from '../../../utils/formatTime';
// _mock_
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useLocales from '../../../hooks/useLocales';

// ----------------------------------------------------------------------
const _notifications = [
  {
    id: 162589,
    isUnRead: true,
    certificateID: 1414312000124,
    certificateSeries: 'KC',
    registrationType: false,
    certificateSaleShopID: 10,
    certificateSaleShop: {
      id: 10,
      description: null,
      name: 'Kcell',
      emissionAllowed: true,
      defaultSeries: 'KC',
      isActive: true,
      countryID: 1,
      country: null,
    },
    certificateCostID: null,
    statusUserCertificat: 0,
    managerId: 370,
    certificateCost: 351400,
    merchandiseCost: 2342400,
    managerNames: 'лорп морп',
    managerShopBranchID: 312,
    managerShopBranch: {
      id: 312,
      name: 'Kcell',
      address: 'TEST Kcell Store',
      shopID: 10,
      shop: null,
      description: 'TEST Kcell Store',
      cityId: 7,
      city: null,
      isActive: true,
      mobProCertificate: false,
      shopCertificate: false,
      externalShopID: null,
      longitude: null,
      latitude: null,
    },
    managerEMail: 'sashapazyuk1@gmail.com',
    managerPhone: '+380966253191',
    deviceID: 3399,
    device: {
      id: 3399,
      name: 'Apple iPad 2018 32GB WiFi',
      isActive: false,
      dictDeviceBrandID: 3,
      dictDeviceBrand: {
        id: 3,
        name: 'Apple',
        url: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/document/brand/7ae7e521-c49b-4e01-9e68-be69211f184c.png',
        popularity: 1,
        isActive: true,
        lastUpdatedBy: 4,
        lastUpdatedDateTime: '2021-04-07T17:19:49.377',
      },
      costNewDeviceKZT: 119990,
      costNewDeviceUZS: 0,
      costBuDevice: 115000,
      categoryId: 1,
      category: null,
      deviceCostHistory: [
        {
          CostNewDeviceKZT: null,
          CostNewDeviceUZS: null,
          CostBuDevice: 115000.0,
          ChangeDate: '2020-09-09T08:18:12.953',
        },
        {
          CostNewDeviceKZT: null,
          CostNewDeviceUZS: null,
          CostBuDevice: 115000.0,
          ChangeDate: '2020-09-09T08:27:19.383',
        },
        {
          CostNewDeviceKZT: null,
          CostNewDeviceUZS: null,
          CostBuDevice: 115000.0,
          ChangeDate: '2020-09-09T10:44:42.853',
        },
        {
          CostNewDeviceKZT: null,
          CostNewDeviceUZS: null,
          CostBuDevice: 115000.0,
          ChangeDate: '2021-03-16T14:14:03.407',
        },
        {
          CostNewDeviceKZT: 119990,
          CostNewDeviceUZS: null,
          CostBuDevice: 115000.0,
          ChangeDate: '2021-03-16T14:17:42.433',
        },
        {
          CostNewDeviceKZT: 119990,
          CostNewDeviceUZS: 0,
          CostBuDevice: 115000.0,
          ChangeDate: '2021-06-09T15:04:45.297',
        },
      ],
      createDate: '2020-09-09T00:00:00',
      priceUpdateDate: '2021-10-05T08:11:36.407',
      lastUpdatedDateTime: '2021-10-05T08:11:43.133',
    },
    deviceIDString: null,
    deviceIMEI: 'gh',
    deviceUnusableDeviceReasonID: 0,
    deviceUnusableDeviceReasonString: 'gj',
    deviceDamageCausesDescription: 'не был введен',
    creditAgreementNumber: null,
    customerIIN: null,
    customerINN: null,
    deviceWarrantyCard: false,
    devicePackaging: false,
    deviceAccessories: false,
    deviceApproved: false,
    approved: false,
    rejectionReason: null,
    clarifyComment: null,
    compensationAmount: 0,
    compensationAmountAdmin: 0,
    adminComment: null,
    managerComment: 'не был введен',
    archive: {
      DamageDevicePhotos1: {
        Id: -1,
        Key: 'DamageDevicePhotos1',
        FileName: 'unnamed.jpg',
        Extention: null,
        Value: null,
        Path: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000124/unnamed.jpg',
        Base64: null,
      },
      IMEIphotoResource: {
        Id: -1,
        Key: 'IMEIphotoResource',
        FileName: 'unnamed.jpg',
        Extention: null,
        Value: null,
        Path: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000124/unnamed.jpg',
        Base64: null,
      },
    },
    certificateSaleDate: '2021-10-12T00:00:00',
    queryFillDate: '2021-10-18T08:55:17.497',
    approvedDate: null,
    rejectionDate: null,
    clarifyDate: null,
    contactBuyer: '+380966253194',
    firstNameBuyer: 'kh',
    lastNameBuyer: 'kjn',
    buyerEmail: 'lh',
    appData: null,
    isActive: true,
    additionalApprovalStatus: null,
    additionalApprovalDate: null,
    flowType: null,
    isDeleted: false,
    integrationStatus: null,
    chatId: null,
    requestType: 'Offline',
    sourceType: 'Office',
    saleType: 'None',
    customerSelectedShopBranch: null,
  },
  {
    id: 162586,
    isUnRead: true,
    certificateID: 1414312000120,
    certificateSeries: 'KC',
    registrationType: false,
    certificateSaleShopID: 10,
    certificateSaleShop: {
      id: 10,
      description: null,
      name: 'Kcell',
      emissionAllowed: true,
      defaultSeries: 'KC',
      isActive: true,
      countryID: 1,
      country: null,
    },
    certificateCostID: 0,
    statusUserCertificat: null,
    managerId: null,
    certificateCost: 1125000,
    merchandiseCost: 7500000,
    managerNames: 'Keep Talk',
    managerShopBranchID: 31329,
    managerShopBranch: {
      id: 31329,
      name: 'Kcell',
      address: 'Kcell. г. Кызылорда, ул. Коркыт Ата, 21',
      shopID: 10,
      shop: null,
      description: null,
      cityId: 50,
      city: null,
      isActive: true,
      mobProCertificate: false,
      shopCertificate: false,
      externalShopID: null,
      longitude: null,
      latitude: null,
    },
    managerEMail: 'sashapazyuk1@gmail.com',
    managerPhone: '+380966253191',
    deviceID: 3604,
    device: {
      id: 3604,
      name: 'Xiaomi Redmi Note 9T  128GB',
      isActive: true,
      dictDeviceBrandID: 19,
      dictDeviceBrand: {
        id: 19,
        name: 'Xiaomi',
        url: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/document/brand/1bc00efb-77b9-4993-b10a-2302c04e1d87.png',
        popularity: 4,
        isActive: true,
        lastUpdatedBy: 4,
        lastUpdatedDateTime: '2021-04-07T17:19:02.407',
      },
      costNewDeviceKZT: 100000,
      costNewDeviceUZS: 0,
      costBuDevice: 70000,
      categoryId: 1,
      category: null,
      deviceCostHistory:
        '[{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":100000.0,"ChangeDate":"2021-01-12T11:39:26.373"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":100000.0,"ChangeDate":"2021-01-13T15:39:45.957"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":100000.0,"ChangeDate":"2021-01-13T15:54:09.687"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":100000.0,"ChangeDate":"2021-01-13T15:58:17.527"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":70000.0,"ChangeDate":"2021-03-03T09:52:30.647"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":70000.0,"ChangeDate":"2021-03-16T14:17:46.087"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":70000.0,"ChangeDate":"2021-03-18T10:58:39.477"},{"CostNewDeviceKZT":100000,"CostNewDeviceUZS":null,"CostBuDevice":70000.0,"ChangeDate":"2021-03-18T11:00:41.607"},{"CostNewDeviceKZT":100000,"CostNewDeviceUZS":0,"CostBuDevice":70000.0,"ChangeDate":"2021-06-09T15:04:46.023"}]',
      createDate: '2020-04-30T00:00:00',
      priceUpdateDate: '2021-10-05T08:11:37.23',
      lastUpdatedDateTime: '2022-02-22T09:59:29.86',
    },
    deviceIDString: 'Xiaomi Redmi Note 9T  128GB,Xiaomi',
    deviceIMEI: '65465465',
    deviceUnusableDeviceReasonID: 0,
    deviceUnusableDeviceReasonString: 'Повреждение в результате затопления, контакта с водой',
    deviceDamageCausesDescription: null,
    creditAgreementNumber: null,
    customerIIN: '654654654666',
    customerINN: null,
    deviceWarrantyCard: true,
    devicePackaging: true,
    deviceAccessories: true,
    deviceApproved: true,
    approved: true,
    rejectionReason: null,
    clarifyComment: null,
    compensationAmount: 5250000,
    compensationAmountAdmin: 0,
    adminComment: null,
    managerComment: null,
    archive:
      '{"CopySalesReceipt":{"fileName":"test.jpg","base64":"","key":"CopySalesReceipt"},"CopySertificat":{"fileName":"test.jpg","base64":"","key":"CopySertificat"},"CopyDocument":{"fileName":"test.jpg","base64":"","key":"CopyDocument"},"DamageDevicePhotos1":{"fileName":"test.jpg","base64":"","Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000120/bea59425-77e9-4b96-97d8-fa73d195cf21.jpg","key":"DamageDevicePhotos1"},"DamageDevicePhotos2":{"fileName":"test.jpg","base64":"","key":"DamageDevicePhotos2"},"DamageDevicePhotos3":{"fileName":"test.jpg","base64":"","key":"DamageDevicePhotos3"},"IMEIphotoResource":{"fileName":"test.jpg","base64":"","Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000120/728ca10d-06f8-479b-b64a-864b9f9960ea.jpg","key":"IMEIphotoResource"},"WarrantyCardPhoto":{"fileName":"test.jpg","base64":"","key":"WarrantyCardPhoto"}}',
    certificateSaleDate: '2021-09-24T00:00:00',
    queryFillDate: '2021-09-24T15:01:41',
    approvedDate: '2021-09-24T15:22:12.537',
    rejectionDate: null,
    clarifyDate: null,
    contactBuyer: '+380966253194',
    firstNameBuyer: 'newUser',
    lastNameBuyer: 'test',
    buyerEmail: '1sashapazyuk1@gmail.com',
    appData: '{"notifyToken":null,"isFullExchange":true,"managerId":"0","customerId":"10063","push_vendor":null}',
    isActive: true,
    additionalApprovalStatus: 'Approved',
    additionalApprovalDate: '2021-09-24T15:22:12.537',
    flowType: null,
    isDeleted: false,
    integrationStatus: null,
    chatId: null,
    requestType: 'Online',
    sourceType: 'KTApp',
    saleType: 'None',
    customerSelectedShopBranch: null,
  },
  {
    id: 162582,
    certificateID: 1414312000097,
    certificateSeries: 'KC',
    registrationType: false,
    certificateSaleShopID: 10,
    certificateSaleShop: {
      id: 10,
      description: null,
      name: 'Kcell',
      emissionAllowed: true,
      defaultSeries: 'KC',
      isActive: true,
      countryID: 1,
      country: null,
    },
    certificateCostID: null,
    statusUserCertificat: 0,
    managerId: 370,
    certificateCost: 35140000,
    merchandiseCost: 2342400,
    managerNames: 'лорп морп',
    managerShopBranchID: 312,
    managerShopBranch: {
      id: 312,
      name: 'Kcell',
      address: 'TEST Kcell Store',
      shopID: 10,
      shop: null,
      description: 'TEST Kcell Store',
      cityId: 7,
      city: null,
      isActive: true,
      mobProCertificate: false,
      shopCertificate: false,
      externalShopID: null,
      longitude: null,
      latitude: null,
    },
    managerEMail: 'sashapazyuk1@gmail.com',
    managerPhone: '+380966253191',
    deviceID: 3398,
    device: {
      id: 3398,
      name: 'Apple iPad 2017 32GB WiFi ',
      isActive: false,
      dictDeviceBrandID: 3,
      dictDeviceBrand: {
        id: 3,
        name: 'Apple',
        url: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/document/brand/7ae7e521-c49b-4e01-9e68-be69211f184c.png',
        popularity: 1,
        isActive: true,
        lastUpdatedBy: 4,
        lastUpdatedDateTime: '2021-04-07T17:19:49.377',
      },
      costNewDeviceKZT: 239990,
      costNewDeviceUZS: 0,
      costBuDevice: 10000,
      categoryId: 1,
      category: null,
      deviceCostHistory:
        '[{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":0.0,"ChangeDate":"2020-09-09T08:18:12.953"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":10000.0,"ChangeDate":"2020-09-09T08:27:19.383"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":10000.0,"ChangeDate":"2020-09-09T10:44:42.853"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":10000.0,"ChangeDate":"2021-03-16T14:14:03.407"},{"CostNewDeviceKZT":239990,"CostNewDeviceUZS":null,"CostBuDevice":10000.0,"ChangeDate":"2021-03-16T14:17:40.233"},{"CostNewDeviceKZT":239990,"CostNewDeviceUZS":0,"CostBuDevice":10000.0,"ChangeDate":"2021-06-09T15:04:45.297"}]',
      createDate: '2020-09-09T00:00:00',
      priceUpdateDate: '2021-10-05T08:11:36.407',
      lastUpdatedDateTime: '2021-10-05T08:11:43.097',
    },
    deviceIDString: null,
    deviceIMEI: '1234234',
    deviceUnusableDeviceReasonID: 3,
    deviceUnusableDeviceReasonString: null,
    deviceDamageCausesDescription: 'не был введен',
    creditAgreementNumber: null,
    customerIIN: null,
    customerINN: null,
    deviceWarrantyCard: false,
    devicePackaging: false,
    deviceAccessories: false,
    deviceApproved: false,
    approved: false,
    rejectionReason: null,
    clarifyComment: null,
    compensationAmount: 0,
    compensationAmountAdmin: 0,
    adminComment: null,
    managerComment: 'не был введен',
    archive:
      '{"DamageDevicePhotos1":{"Id":-1,"Key":"DamageDevicePhotos1","FileName":"luna-noch-krovavaia-luna-oboi.jpg","Extention":null,"Value":null,"Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000097/luna-noch-krovavaia-luna-oboi.jpg","Base64":null},"IMEIphotoResource":{"Id":-1,"Key":"IMEIphotoResource","FileName":"download (1).jpg","Extention":null,"Value":null,"Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000097/download (1).jpg","Base64":null}}',
    certificateSaleDate: '2021-06-10T00:00:00',
    queryFillDate: '2021-07-29T08:27:55.96',
    approvedDate: null,
    rejectionDate: null,
    clarifyDate: null,
    contactBuyer: '+380966253194',
    firstNameBuyer: 'iuh',
    lastNameBuyer: 'oiuyg',
    buyerEmail: 'giuy',
    appData: null,
    isActive: true,
    additionalApprovalStatus: null,
    additionalApprovalDate: null,
    flowType: null,
    isDeleted: false,
    integrationStatus: null,
    chatId: null,
    requestType: 'Offline',
    sourceType: 'Office',
    saleType: 'None',
    customerSelectedShopBranch: null,
  },
  {
    id: 162580,
    certificateID: 1414312000099,
    certificateSeries: 'KC',
    registrationType: false,
    certificateSaleShopID: 10,
    certificateSaleShop: {
      id: 10,
      description: null,
      name: 'Kcell',
      emissionAllowed: true,
      defaultSeries: 'KC',
      isActive: true,
      countryID: 1,
      country: null,
    },
    certificateCostID: 0,
    statusUserCertificat: null,
    managerId: null,
    certificateCost: 351400,
    merchandiseCost: 2342400,
    managerNames: 'лорп морп',
    managerShopBranchID: 31319,
    managerShopBranch: {
      id: 31319,
      name: 'Kcell',
      address: 'Kcell. г. Актобе, ул. Маметовой, 4, ТРЦ "KeruenСity"',
      shopID: 10,
      shop: null,
      description: null,
      cityId: 13,
      city: null,
      isActive: true,
      mobProCertificate: false,
      shopCertificate: false,
      externalShopID: null,
      longitude: null,
      latitude: null,
    },
    managerEMail: 'sashapazyuk1@gmail.com',
    managerPhone: '+380966253191',
    deviceID: 0,
    device: {
      id: 0,
      name: 'Другая модель',
      isActive: true,
      dictDeviceBrandID: 0,
      dictDeviceBrand: {
        id: 0,
        name: 'Другой производитель',
        url: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/document/brand/4b4538e1-c638-4b35-9a39-e51b71b9adea.png',
        popularity: 10,
        isActive: true,
        lastUpdatedBy: 0,
        lastUpdatedDateTime: '2021-10-25T14:00:21.807',
      },
      costNewDeviceKZT: 50000,
      costNewDeviceUZS: null,
      costBuDevice: 10000,
      categoryId: 1,
      category: null,
      deviceCostHistory: null,
      createDate: '2020-09-09T00:00:00',
      priceUpdateDate: '2020-09-09T00:00:00',
      lastUpdatedDateTime: '2022-02-22T09:59:30.017',
    },
    deviceIDString: 'test qwq,',
    deviceIMEI: "';ij;",
    deviceUnusableDeviceReasonID: 0,
    deviceUnusableDeviceReasonString:
      'Повреждение в результате перепада напряжения в сети, удара электрическим током, замыкания в сети',
    deviceDamageCausesDescription: null,
    creditAgreementNumber: null,
    customerIIN: '121212121212',
    customerINN: null,
    deviceWarrantyCard: true,
    devicePackaging: true,
    deviceAccessories: true,
    deviceApproved: true,
    approved: true,
    rejectionReason: null,
    clarifyComment: null,
    compensationAmount: 1639680,
    compensationAmountAdmin: 0,
    adminComment: null,
    managerComment: null,
    archive:
      '{"CopySalesReceipt":{"fileName":"test.jpg","base64":"","key":"CopySalesReceipt"},"CopySertificat":{"fileName":"test.jpg","base64":"","key":"CopySertificat"},"CopyDocument":{"fileName":"test.jpg","base64":"","key":"CopyDocument"},"DamageDevicePhotos1":{"fileName":"test.jpg","base64":"","Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000099/0094fbe1-9143-4e3a-9ccc-44c8bafba11d.jpg","key":"DamageDevicePhotos1"},"DamageDevicePhotos2":{"fileName":"test.jpg","base64":"","key":"DamageDevicePhotos2"},"DamageDevicePhotos3":{"fileName":"test.jpg","base64":"","key":"DamageDevicePhotos3"},"IMEIphotoResource":{"fileName":"test.jpg","base64":"","Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000099/53c82bd5-9a2a-4168-873f-eabde796401a.jpg","key":"IMEIphotoResource"},"WarrantyCardPhoto":{"fileName":"test.jpg","base64":"","key":"WarrantyCardPhoto"}}',
    certificateSaleDate: '2021-06-10T00:00:00',
    queryFillDate: '2021-06-10T08:31:31',
    approvedDate: '2021-06-10T08:33:29.88',
    rejectionDate: null,
    clarifyDate: null,
    contactBuyer: '+380966253194',
    firstNameBuyer: 'newUser',
    lastNameBuyer: 'test',
    buyerEmail: '1sashapazyuk1@gmail.com',
    appData: '{"notifyToken":null,"isFullExchange":true,"managerId":"0","customerId":"10063","push_vendor":null}',
    isActive: true,
    additionalApprovalStatus: 'Approved',
    additionalApprovalDate: '2021-06-10T08:33:29.88',
    flowType: null,
    isDeleted: false,
    integrationStatus: 'UsedInKcell',
    chatId: null,
    requestType: 'Online',
    sourceType: 'KTApp',
    saleType: 'None',
    customerSelectedShopBranch: null,
  },
  {
    id: 162575,
    certificateID: 1414312000095,
    certificateSeries: 'KC',
    registrationType: false,
    certificateSaleShopID: 10,
    certificateSaleShop: {
      id: 10,
      description: null,
      name: 'Kcell',
      emissionAllowed: true,
      defaultSeries: 'KC',
      isActive: true,
      countryID: 1,
      country: null,
    },
    certificateCostID: 0,
    statusUserCertificat: null,
    managerId: null,
    certificateCost: 1125000,
    merchandiseCost: 7500000,
    managerNames: 'nbljh bjh',
    managerShopBranchID: 312,
    managerShopBranch: {
      id: 312,
      name: 'Kcell',
      address: 'TEST Kcell Store',
      shopID: 10,
      shop: null,
      description: 'TEST Kcell Store',
      cityId: 7,
      city: null,
      isActive: true,
      mobProCertificate: false,
      shopCertificate: false,
      externalShopID: null,
      longitude: null,
      latitude: null,
    },
    managerEMail: 'sashapazyuk1@gmail.com',
    managerPhone: '+380966253191',
    deviceID: 13830,
    device: {
      id: 13830,
      name: 'Xiaomi Redmi Note 9S 64 GB',
      isActive: true,
      dictDeviceBrandID: 19,
      dictDeviceBrand: {
        id: 19,
        name: 'Xiaomi',
        url: 'https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/document/brand/1bc00efb-77b9-4993-b10a-2302c04e1d87.png',
        popularity: 4,
        isActive: true,
        lastUpdatedBy: 4,
        lastUpdatedDateTime: '2021-04-07T17:19:02.407',
      },
      costNewDeviceKZT: 111830,
      costNewDeviceUZS: 0,
      costBuDevice: 0,
      categoryId: 1,
      category: null,
      deviceCostHistory:
        '[{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":49000.0,"ChangeDate":"2021-03-16T14:17:46.087"},{"CostNewDeviceKZT":null,"CostNewDeviceUZS":null,"CostBuDevice":49000.0,"ChangeDate":"2021-03-18T10:58:39.483"},{"CostNewDeviceKZT":88000,"CostNewDeviceUZS":null,"CostBuDevice":49000.0,"ChangeDate":"2021-03-18T11:00:41.613"},{"CostNewDeviceKZT":88000,"CostNewDeviceUZS":0,"CostBuDevice":49000.0,"ChangeDate":"2021-06-09T15:04:46.02"},{"CostNewDeviceKZT":88000,"CostNewDeviceUZS":0,"CostBuDevice":49000.0,"ChangeDate":"2021-10-05T08:11:37.227"}]',
      createDate: '2020-04-30T00:00:00',
      priceUpdateDate: '2021-10-05T09:09:23.1',
      lastUpdatedDateTime: '2022-02-22T09:59:29.82',
    },
    deviceIDString: 'Xiaomi Redmi Note 9S 64 GB,Xiaomi',
    deviceIMEI: 'n m',
    deviceUnusableDeviceReasonID: null,
    deviceUnusableDeviceReasonString: 'Гарантийные поломки, предусмотренные условиями гарантии производителя',
    deviceDamageCausesDescription: 'Другая причина',
    creditAgreementNumber: null,
    customerIIN: 'bkj',
    customerINN: null,
    deviceWarrantyCard: true,
    devicePackaging: true,
    deviceAccessories: true,
    deviceApproved: true,
    approved: false,
    rejectionReason: null,
    clarifyComment: null,
    compensationAmount: null,
    compensationAmountAdmin: null,
    adminComment: null,
    managerComment: null,
    archive:
      '{"DamageDevicePhotos1":{"key":"DamageDevicePhotos1","Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000095/file_25.jpg","file_id":"AgACAgIAAxkBAAICkGC473DfXefD2UoYR9tV4WOdOuABAALwtDEbz5_ASfeaA7o-kueWDLqkoi4AAwEAAwIAA20AA-8uAwABHwQ"},"IMEIphotoResource":{"key":"IMEIphotoResource","Path":"https://mobileco-certificates.s3.eu-central-1.amazonaws.com/prod/exchange/KC-1414312000095/file_32.jpg","file_id":"AgACAgIAAxkBAAICkmC474IHQc1bWDl5jp0Xh2TPP8XSAAJGtTEbz5_ISZ8saujjznYa4f8Eny4AAwEAAwIAA3kAA6jvAgABHwQ"}}',
    certificateSaleDate: '2021-06-03T00:00:00',
    queryFillDate: '2021-06-03T15:03:47',
    approvedDate: null,
    rejectionDate: null,
    clarifyDate: null,
    contactBuyer: '+380966253194',
    firstNameBuyer: null,
    lastNameBuyer: null,
    buyerEmail: 'hbjbh',
    appData: '{"notifyToken":null,"isFullExchange":true,"managerId":"0","customerId":null,"push_vendor":null}',
    isActive: true,
    additionalApprovalStatus: null,
    additionalApprovalDate: null,
    flowType: 'None',
    isDeleted: false,
    integrationStatus: null,
    chatId: null,
    requestType: 'Online',
    sourceType: 'TLBot',
    saleType: 'None',
    customerSelectedShopBranch: null,
  },
];

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState<any>(_notifications);
  const navigate = useNavigate();
  const { translate } = useLocales();
  const totalUnRead = notifications.filter((item: any) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event: ChangeEvent<any>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification: any) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        disabled
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        {/*<Badge badgeContent={totalUnRead} color="error">*/}
        <Iconify icon="eva:bell-fill" width={20} height={20} />
        {/*</Badge>*/}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('NOTIFICATION.TITLE')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${translate('NOTIFICATION.YOU_HAVE')} ${totalUnRead} ${translate('NOTIFICATION.NEW_NOTIFICATION')}`}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={`${translate('NOTIFICATION.READ_ALL')}`}>
              <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {translate('NOTIFICATION.NEW')}
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification: any) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {translate('NOTIFICATION.EARLIER')}
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification: any) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth onClick={() => navigate(PATH_DASHBOARD.general.exchange)}>
            {translate('NOTIFICATION.SEE_ALL')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification }: any) {
  const { avatar, title } = renderContent(notification);
  const navigate = useNavigate();

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() => navigate(PATH_DASHBOARD.general.exchange)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {notification.queryFillDate ? fToNow(notification.queryFillDate) : ''}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: any) {
  const title = (
    <Typography variant="subtitle2">
      {notification.certificateSaleShop?.name}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {`${notification.certificateSeries} ${notification.certificateID}`}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : <AssignmentIcon />,
    title,
  };
}
