import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import filtersReducer from './slices/filters';
import utilsReducer from './slices/utils';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['user'],
};

const filtersPersistConfig = {
  key: 'filters',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sale', 'exchange'],
};

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  filters: persistReducer(filtersPersistConfig, filtersReducer),
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  utils: utilsReducer,
});

export { rootPersistConfig, rootReducer };
