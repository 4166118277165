import { createSlice } from '@reduxjs/toolkit';
import { dispatch, store } from '../store';
import authService from '../../services/authService';
import { setSession } from '../../utils/jwt';
import { ROLE_TYPES } from '../../constants/enums';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  isLoading: false,
  showWelcomeModal: false,
  error: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    loginSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    openWelcomeModal(state) {
      state.showWelcomeModal = true;
    },

    closeWelcomeModal(state) {
      state.showWelcomeModal = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function login(userName: string, password?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await authService.token({ userName, password })

      setSession(response.access_token, response.refresh_token);

      const user = await authService.getCurrentUser()
      dispatch(slice.actions.loginSuccess(user));

      // open welcome modal for Freedom shop
      if (user.role?.roleType !== ROLE_TYPES.ADMIN && user.role?.shopIds.includes(21) ) {
        dispatch(slice.actions.openWelcomeModal())
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error
    }
  };
}

export function tryToReLogin() {
  return new Promise( (resolve, reject) => {
    const { user } = store.getState();
    const refreshToken = String(localStorage.getItem('refreshToken'));
    authService.token({ userName: user?.user?.userName, refreshToken })
      .then((response) => {
        setSession(response.access_token, response.refresh_token);
        resolve(true);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function updateUserData() {
  return async () => {
    try {
      const user = await authService.getCurrentUser()
      dispatch(slice.actions.loginSuccess(user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function logout() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.loginSuccess(null));
      // await authService.logout()
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function closeWelcomeModal() {
  return async () => {
    dispatch(slice.actions.closeWelcomeModal());
  };
}

// ----------------------------------------------------------------------
