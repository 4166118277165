import { useTranslation } from 'react-i18next';
// '@mui
import { enUS, deDE, frFR, ruRU, kzKZ } from '@mui/material/locale';
import { ru, enUS as en, kk } from 'date-fns/locale';


// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'Казахский',
    value: 'kz',
    systemValue: kzKZ,
    dateLocale: kk,
    icon: '/icons/ic_kz.svg',
  },
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    dateLocale: ru,
    icon: '/icons/ic_ru.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    dateLocale: en,
    icon: '/icons/ic_en.svg',
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
